import React from 'react';

const SponsorsMentors = () => {
    return (
        <div className="document">
            <h1>🆕 Video Greetings from Sponsors & Mentors</h1>
            <p>
                🚲 <a href={"https://drive.nyx.baby/nyxnocode/Team001@mena.nyxnocode.com/Page_Content_DOCTYPE_html_html_langen_head_meta_charsetUTF8-20241115-184347.html"}>Michael & Clayton from LockStop</a>
            </p>

            <h1>🤝 Sponsors & Mentors</h1>
            <p>
                The Mena School District NoCode Hackathon is made possible through the generous support of our sponsors and mentors. Here’s a bit about each sponsor and how they’re contributing to this event.
            </p>

            <h2>Walmart</h2>
            <p>
                <strong>Role:</strong> Walmart is providing $5 gift cards and swag for each student to celebrate your hard work. They’re excited to see what young innovators like you can achieve!
            </p>

            <h2>NYX NoCode LLC</h2>
            <p>
                <strong>Role:</strong> NYX NoCode is the main platform you’ll use for the hackathon, designed by Karen Kilroy, who’s passionate about helping everyone create through technology.
            </p>

            <h2>Startup Junkie & ARise</h2>
            <p>
                <strong>Role:</strong> These organizations support entrepreneurs and tech talent in Arkansas. ARise aims to empower tech ventures in the state, and Startup Junkie provides mentorship to help you build skills for future innovation. Both are actively mentoring startups Lock Stop and NYX NoCode.
            </p>

            <h2>LockStop</h2>
            <p>
                <strong>Role:</strong> LockStop is all about keeping bikes safe and accessible. They’re partnering to provide resources for the event and look forward to seeing how your projects make biking in Mena even better.
            </p>

            <h2>AWS</h2>
            <p>
                <strong>Role:</strong> AWS is supplying hard copies of Natural Language and Search for our winning teams, which was co-written by Karen Kilroy along with AWS engineers and explains how LLMs work. (O'Reilly, 2024).
            </p>

            <h2>O'Reilly</h2>
            <p>
                <strong>Role:</strong> O'Reilly will supply autographed copies of Karen Kilroy's Blockchain Tethered AI book the winning team members. Also, each participant will get a free 30-day access to O'Reilly library of technical books and training material.
            </p>
            <h2>Microsoft</h2>
            <p>
                <strong>Role: </strong>Cloud Provider. Due to Microsoft's generous support to NYX NoCode by providing cloud credits and education, this NoCode Hackathon is has gone from dream to reality.
            </p>

            <h2>Mentors</h2>
            <p>
                <strong>Who They Are:</strong> Your mentors include software engineers, designers, and project managers from Walmart, Startup Junkie, ARise, and NYX NoCode.
            </p>
            <p>
                <strong>How They’ll Help:</strong> Mentors will be available throughout the hackathon to provide guidance, troubleshoot issues, and help bring your ideas to life.
            </p>
        </div>
    );
};

export default SponsorsMentors;
